
import { defineComponent, onBeforeMount, onMounted, onUnmounted, PropType, ref, watch } from "vue"
import FilterBase from "@/layout/header/components/FilterBase.vue"
import axios from "axios"
import useAlert from "@/composables/Alert"
import { ICompanyData } from "@/store/CompaniesStore"
import { useFilterBaseV2Store } from "@/store/FilterBaseV2Store"
import { useCompanyStore } from "@/store/CompanyStore"
import { useStoreStore } from "@/store/StoreStore"
import { campCreateUnlinkedClone } from "@/composables/DataValidation"

type TCompanyList = { id: number | string, label: string, cnpj?: string }

export interface ICompanyListResponse {
  data: {
    error: boolean,
    message: string,
    data: ICompanyData[] | null,
    errorCode: string,
  }
}

export interface IEmitGlobalFields {
  companyId: number | null,
  storeId: number | null,
}

export interface IEmitRequestFlow extends IEmitGlobalFields {
  error: boolean,
  loading: boolean,
}

const INIT_API_EMIT:IEmitRequestFlow = {
  companyId: null,
  storeId: null,
  error: false,
  loading: false,
}

const INIT_CLIENT_EMIT:IEmitGlobalFields = {
  companyId: null,
  storeId: null,
}

interface SearchForm<T = Record<string, any>> {
  [key: string]: any;
}

export default defineComponent({
  name: "FilterBaseV2",
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: "Filtros",
    },
    subtitle: {
      type: String,
      default: ""
    },
    searchForm: {
      type: [Object, null] as PropType<SearchForm | null>,
      default: null
    },
    // searchTitle: {
    //   type: String,
    //   default: "",
    // },
    routeNameToRedirect: {
      type: String,
      default: 'storeList'
    },
    goToBackBtnDesc: {
      type: String,
      default: 'Ir para Lojas'
    },
    showGoToBack: {
      type: Boolean,
      default: false
    },
    activeModal: {
      type: Boolean,
      default: false
    },
    hideFilter: {
      type: Boolean,
      default: false
    },
    staticModal: {
      type: Boolean,
      default: true
    },
    hideClose: {
      type: Boolean,
      default: false
    },
    closeModal: {
      type: Boolean,
      default: false
    },
    disabledClick: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    modalWidth: {
      type: String,
      default: "500px"
    },
    displayAllCompanies: {
      type: Boolean,
      default: false
    },
    displayAllStores: {
      type: Boolean,
      default: false
    },
    showCompanyField: {
      type: Boolean,
      default: true
    },
    showStoreField: {
      type: Boolean,
      default: false
    },
    companyFieldBootstrapClass: {
      type: String,
      default: "col-md-12"
    },
    storeFieldBootstrapClass: {
      type: String,
      default: "col-md-12"
    },
  },
  components: {
    FilterBase,
  },
  setup(props, { emit }) {
    /** Variables */
    const { showTimeAlert } = useAlert()
    const isLoading = ref(true)
    const filterBaseV2Store = useFilterBaseV2Store()
    const companyId = ref<number | string>("")
    const companyFieldWarning = ref(false)
    const storeId = ref<number | string>("")
    const storeFieldWarning = ref(false)
    const fieldWasClicked = ref(false)
    const companyList = ref<TCompanyList[] | null>(null)
    const storeList = ref<TCompanyList[] | null>(null)
    const apiDataEmit = ref<IEmitRequestFlow>({ ...INIT_API_EMIT })
    const clientDataEmit = ref<IEmitGlobalFields>({ ...INIT_CLIENT_EMIT })

    /** Functions */
    function handleClientDataEmit(companyId: number | null, storeId: number | null) {
      clientDataEmit.value = {
        companyId,
        storeId
      }
      emit('watch:changesGlobalFields', { ...clientDataEmit.value })
    }

    async function handleInitFields() {
      await filterBaseV2Store.getCompanyAndStoreInSession()
      companyId.value = filterBaseV2Store.company?.id || ""
      storeId.value = filterBaseV2Store.store?.id || ""
      await handleGetDatas()
    }

    async function handleGetDatas() {
      try {
        isLoading.value = true

        let treatedValueCompanyId: null | number = null
        if(typeof companyId.value === "number" && companyId.value > 0)
          treatedValueCompanyId = companyId.value

        const [ companySessionResponse, storeSessionResponse ] : [ ICompanyListResponse, ICompanyListResponse ] = [
          await axios.get("/api/getCompanyList"),
          await axios.get(`/api/getStore?id_company=${treatedValueCompanyId}`)
        ]

        if(Array.isArray(companySessionResponse.data.data)) {
          companyList.value = companySessionResponse.data.data.map(el => ({ id: el.id, label: el.fantasy_name, cnpj: el.cnpj }))
          if(props.displayAllCompanies)
            companyList.value = [ { id: "", label: "Todas as Companias" }, ...companyList.value ]
        }

        if(Array.isArray(storeSessionResponse.data.data)) {
          storeList.value = storeSessionResponse.data.data.map(el => ({ id: el.id, label: el.fantasy_name, cnpj: el.cnpj }))
          if(props.displayAllStores)
            storeList.value = [ { id: "", label: "Todas as Lojas" }, ...storeList.value ]
        }
      } catch (error) {
        console.error(error)
      } finally {
        isLoading.value = false
      }
    }

    async function handleGetStoreList(id_company: string | number) {
      try {
        isLoading.value = true
        storeId.value = ""
        const response: ICompanyListResponse = await axios.get(`/api/getStore?id_company=${id_company}`)

        if(Array.isArray(response.data.data)) {
          storeList.value = response.data.data.map(el => ({ id: el.id, label: el.fantasy_name, cnpj: el.cnpj }))
          if(props.displayAllStores)
            storeList.value = [ { id: "", label: "Todas as Lojas" }, ...storeList.value ]
        }
      } catch (error) {
        console.error(error)
      } finally {
        isLoading.value = false
      }
    }

    async function handleSetDataInSession() {
      if(props.showCompanyField && !props.displayAllCompanies && !companyId.value) {
        companyFieldWarning.value = true
        return
      }

      if(props.showStoreField && !props.displayAllStores && !storeId.value) {
        storeFieldWarning.value = true
        return
      }

      try {
        isLoading.value = true
        apiDataEmit.value.loading = isLoading.value
        emit('watch:requestFlow', { ...apiDataEmit.value })

        let treatedValueCompanyId: null | number = null
        if(typeof companyId.value === "number" && companyId.value > 0)
          treatedValueCompanyId = companyId.value

        let treatedValueStoreId: null | number = null
        if(typeof storeId.value === "number" && storeId.value > 0)
          treatedValueStoreId = storeId.value

        await filterBaseV2Store.setCompanyAndStoreInSession(treatedValueCompanyId, treatedValueStoreId)

        apiDataEmit.value.companyId = filterBaseV2Store.company?.id ? filterBaseV2Store.company.id : treatedValueCompanyId
        apiDataEmit.value.storeId = filterBaseV2Store.store?.id ? filterBaseV2Store.store.id : treatedValueStoreId

        /**
         * WARNING!!!
         *
         * After all the global filters have been replaced by the v2 global filter, remove this palliative code.
         * */
        // const currentCompany = await filterBaseV2Store.getCompanyInSession() /** palliative */
        // const currentStore = await filterBaseV2Store.getStoreInSession() /** palliative */
        // useCompanyStore().setCompanyV2(currentCompany) /** palliative */
        // useStoreStore().setStoreV2(currentStore) /** palliative */
        useCompanyStore().setCompanyV2(filterBaseV2Store.company) /** palliative */
        useStoreStore().setStoreV2(filterBaseV2Store.store) /** palliative */
      } catch (error) {
        console.error(error)
        apiDataEmit.value.error = true
      } finally {
        isLoading.value = false
        apiDataEmit.value.loading = isLoading.value
        emit('watch:requestFlow', { ...apiDataEmit.value })
      }
    }

    function handleDisabledStore() {
      if(props.displayAllStores)
        return (Array.isArray(storeList.value) && storeList.value.length < 2) ? true : false
      else
        return (Array.isArray(storeList.value) && storeList.value.length < 1) ? true : false
    }

    /** Life Cycles */
    onBeforeMount(async() => {
      filterBaseV2Store.reset()
      await handleInitFields()
    })

    onMounted(() => {
      filterBaseV2Store.setSearchFormCache(props.searchForm)
      filterBaseV2Store.setSearchForm(props.searchForm)
    })

    watch(() => companyId.value, async () => {
      if(fieldWasClicked.value)
        await handleGetStoreList(companyId.value)
      fieldWasClicked.value = false
    })

    watch(() => { companyId.value, storeId.value }, () => {
      let treatedValueCompanyId: null | number = null
      if(typeof companyId.value === "number" && companyId.value > 0)
        treatedValueCompanyId = companyId.value

      let treatedValueStoreId: null | number = null
        if(typeof storeId.value === "number" && storeId.value > 0)
          treatedValueStoreId = storeId.value

      if(fieldWasClicked.value)
        handleClientDataEmit(treatedValueCompanyId, treatedValueStoreId)

      fieldWasClicked.value = false
    }, { deep: true })

    watch(() => companyId.value, () => companyFieldWarning.value = false)

    watch(() => storeId.value, () => storeFieldWarning.value = false)

    watch(() => props.searchForm, (value) => filterBaseV2Store.setSearchForm(value), { deep: true })

    watch(() => filterBaseV2Store.pageID, () => {
      const company = filterBaseV2Store.company
      if(company)
        companyId.value = company.id

      storeId.value = filterBaseV2Store.store?.id || ""
    })

    onUnmounted(() => filterBaseV2Store.reset())

    return {
      isLoading,
      companyId,
      fieldWasClicked,
      companyFieldWarning,
      storeFieldWarning,
      companyList,
      storeId,
      storeList,
      handleSetDataInSession,
      handleDisabledStore,
    }
  },
})
